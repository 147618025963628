<template>
    <div>
      
      <div class="mx-auto mb-2">
        <b-row class="justify-content-end align-items-center">
          
          <!-- Headers Buttons -->
          <b-col sm="5" >
            <div class="custom-search d-flex justify-content-end">
              <b-button
                v-b-toggle.sidebar-popup  
                variant="primary"
                size="md"
                
              >
                <feather-icon icon="PlusIcon" />
                <span class="align-middle"> Ajouter Pop-up </span>
              </b-button>
            </div>
          </b-col>
          <!-- Sidebar of Add and Update Webinar -->
          <b-sidebar
            id="sidebar-popup"
            ref="sideBarPopup"
            bg-variant="white"
            sidebar-class="sidebar-lg"
            @hidden="hide"
            shadow
            backdrop
            no-header
            right
          >
            <template>
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  content-sidebar-header
                  px-2
                  py-1
                "
              >
                <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
                  {{ title }}
                </h5>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
                />
              </div>
              <validation-observer ref="webinarpopups">
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset.prevent="resetForm"
                >
                  <b-overlay :show="showLoading" no-wrap />
                
                  <b-form-group label="Date De début*" label-for="date-popup"> 
                    <validation-provider v-slot="{ errors }" name="Date du webinar" rules='required'> 
                        <flat-pickr id="date popup" v-model="composeData.date_debut"
                        placeholder="Date de début" :config="configDate" 
                        :class="errors.length > 0 ? 'is-invalid' : ''"  />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                        </b-form-invalid-feedback> 
                    </validation-provider>  
                  </b-form-group>
                  <b-form-group label="Texte à afficher*" label-for="text_afficher">
                    <validation-provider #default="{ errors }" name="message" rules="required">
                      <div style="height: 250px;" class="message-editor" :state="errors.length > 0 ? false : null">
                        <quill-editor style="height: 53%;" v-model="composeData.text" :options="snowOption" :state="errors.length > 0 ? false : null" />
                      </div>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group label="Durée (Jours)*" label-for="Durée">
                    <validation-provider
                      #default="{ errors }"
                      name="Durée"
                      rules="required"
                    >
                      <b-form-input
                        v-model="composeData.duree"
                        id="Duree"
                        type="number"
                        placeholder="Durée"
                        @blur="checkDuree"
                        min="1"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Lien webinar*" label-for="popup_url">
                    <validation-provider
                      #default="{ errors }"
                      name="Lien du webinar"
                      rules="required"
                    >
                      <b-form-input
                        v-model="composeData.popup_url"
                        id="popup_url"
                        placeholder="Lien"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-form-group>
                  <b-button @click="handleSubmit" variant="primary" size="md">
                    <span class="align-middle">Enregistrer</span>
                  </b-button>
                </b-form>
              </validation-observer>
            </template>
          </b-sidebar>
        </b-row>
      </div>
      <!-- List of Webinar -->
      <b-overlay :show="showLoading" no-wrap />
      <vue-good-table
        class="custom-table-bottom"
        :columns="popupsColumns"
        :rows="formattedRows"
        :rtl="direction"
        style-class="vgt-table condensed"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: pageLength,
          rowsPerPageLabel: '',
          dropdownAllowAll: false,
          perPageDropdownEnabled: false,
          nextLabel: 'Suivant',
          prevLabel: 'Précédent',
          ofLabel: 'de',
          infoFn: (params) => ``,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'id'">
            <span  class="cursor-pointer">
              <feather-icon
                @click="deletePopup(props.row.id)"
                icon="TrashIcon"
                size="20"
                class="mr-50"
              />
            </span>
            <span  class="cursor-pointer">
              <b-button v-b-toggle.sidebar-popup
              size="20"
              class="p-0 mr-50"
              variant="none"
              @click="editFunction(props.row)"
              >
              <feather-icon size="20" icon="EditIcon"  class="mr-50" />
              </b-button>
            </span>
          </span>
          <!-- Column Date -->
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <b-row class="mt-2 align-items-center">
            <b-col md="6" lg="5" xxl="3">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Affichage 1 à </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> de {{ props.total }} entrées </span>
              </div>
            </b-col>
          </b-row>
          
          <b-row class="mt-2 align-items-center">
            <b-col>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>
        <div slot="emptystate" class="text-center align-middle">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
      </vue-good-table>
      
    </div>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver,defineRule  } from "vee-validate";
  import { required } from "@validations";
  import flatPickr from 'vue-flatpickr-component'
  import { quillEditor } from 'vue-quill-editor'

  import {
    VBTooltip,
    BFormInvalidFeedback,
    BInputGroupPrepend,
    BFormFile,
    BFormInput,
    BFormGroup,
    BPagination,
    BForm,
    VBToggle,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    BFormSelect,
    BSidebar,
    BFormTextarea,
    BInputGroup,
    BTooltip,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import store from "@/store/index";
  import { VueGoodTable } from "vue-good-table";
  import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from 'moment';
import 'moment/locale/fr'; // Import French locale
  moment.locale('fr');
  export default {
    components: {
      BFormInvalidFeedback,
      VBToggle,
      BInputGroupPrepend,
      BFormFile,
      BFormInput,
      BFormGroup,
      BForm,
      BFormTextarea,
      BInputGroup,
      ValidationProvider,
      ValidationObserver,
      BDropdown,
      BDropdownItem,
      BRow,
      flatPickr,
      BCol,
      BSidebar,
      BButton,
      BCard,
      BCardText,
      BCardHeader,
      BMedia,
      BImg,
      BOverlay,
      VueGoodTable,
      BPagination,
      BFormSelect,
      ToastificationContentVue,
      quillEditor
    },
    directives: {
      "b-toggle": VBToggle,
      "b-tooltip": VBTooltip,
      Ripple,
    },
    props: {
      showInBackOffice: {
        type: Boolean,
        default: false,
      },
      popups:{
        type: Array,
        default: [],
      }
    },
    data() {
      return {
        snowOption: {
        theme: 'snow',
        placeholder: 'Rédiger du texte ici... *'
      },
        showLoading: false,
        pageLength: 5,
        dir: false,
        renderComponent: true,
        modalAction: "Nouveau",
        popupsColumns: [
        {
            label: "Date de début",
            field: "date_debut",
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking w-15",
            tdClass: "text-center m-0 p-2 align-middle w-15",
          },
          {
            label: "Texte du webinar",
            field: row => this.stripHtmlTags(row.text),
            sortable: false,
            thClass: "text-center align-middle bg-primary text-white w-25",
            tdClass: "text-center m-0 p-2 align-middle w-25 ",
          },
          {
            label: "Durée (Jours)",
            field: "duree",
            sortable: true, 
            thClass: "text-center align-middle bg-primary text-white unbreaking ",
            tdClass: "text-center m-0 p-2 align-middle ",
          },
          {
            label: "Statut",
            field: row => this.calculateStatut(row.date_debut, row.duree),
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking w-15",
            tdClass: "text-center m-0 p-2 align-middle w-15",
          },
          {
            label: "Lien Webinar",
            field: "popup_url",
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking w-25;",
            tdClass: "text-center m-0 p-2 align-middle w-25",
          },
          {
            label: "Actions",
            field: "id",
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking",
            tdClass: "text-center m-0  align-middle",
          },
       
        ],
        composeData: {
          id: null,
          date_debut:null,
          text: null,
          duree: null,
          popup_url:null,
        },
        showWebinarURL: false,
        title:'AJOUTER POP-UP',
        configDate: {
          dateFormat: 'Y/m/d',
          altFormat: 'd/m/Y',
          allowInput: true,
          altInput: true,
          disable: [
          function (date) {
            const today = new Date()
            today.setDate(today.getDate())
            today.setHours(0, 0, 0, 0)
            return date < today
          },
        ],  
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode !== 8) { // Exclure la touche de suppression (Backspace)
              const ele = document.activeElement;
              let val = ele.value;

              if ((val.length === 2 || val.length === 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
              }
            },
          }

      };
    },

  computed: {
      direction() {
        if (store.state.appConfig.isRTL) {
          this.dir = true;
          return this.dir;
        }
        this.dir = false;
        return this.dir;
      },
   formattedRows() {
      return this.popups.map(row => ({
        ...row,
         date_debut: row.date_debut ? moment(row.date_debut, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY') : 'Date non disponible' // Formatez ici
      }));
    }
      
},
    methods: {
      stripHtmlTags(text) {
        const doc = new DOMParser().parseFromString(text, 'text/html');
      return doc.body.textContent || "";
    },
     calculateStatut(dateDebut, duree) {
        if (!dateDebut || !duree) return 'Non disponible';

        // Formater la date de début en 'YYYY-MM-DD'
        const formattedDateDebut = moment(dateDebut, 'DD MMMM YYYY', 'fr').format('YYYY-MM-DD');
        const dateDebutMoment = moment(formattedDateDebut, 'YYYY-MM-DD').startOf('day');
        
        // Calculer la date de fin
        const dateFinMoment = dateDebutMoment.clone().add(duree - 1, 'days').endOf('day');
        
        // Date actuelle au début de la journée
        const now = moment().startOf('day');

        // Vérifier si la date actuelle est entre la date de début et la date de fin (inclusivement)
        const isActive = now.isBetween(dateDebutMoment, dateFinMoment, null, '[]');
        const variant = isActive ? '🟢' : '🔴';

        return `${variant} ${isActive ? 'Actif' : 'Inactif'}`;
      },

      checkDuree() {
        if (!this.composeData.duree || this.composeData.duree < 1) {
          this.composeData.duree = 1;
        }
      },
      forceRerender() {
        this.renderComponent = false;
        this.$nextTick(() => {
          this.renderComponent = true;
        });
      },
      hide() {
        this.$refs.sideBarPopup.hide();
        this.clearData();
        this.title='AJOUTER POP-UP'
  
      },
      transformedDate(date) {
      
      const formattedDate = moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY");

      return formattedDate;
    },

      messageToast(text, title, variant) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title,
            icon: "BellIcon",
            text,
            variant,
          },
        });
      },
    
      clearData() {
        this.composeData.id = null;
        this.composeData.text = "";
        this.composeData.duree = "";
        this.composeData.date_debut = "";
        this.composeData.popup_url = ""
        this.$refs.webinarpopups.reset();
      },
      editFunction(data){
        const formattedDate = moment(data.date_debut, 'DD MMMM YYYY', 'fr').format('YYYY-MM-DD');

        console.log(formattedDate)
        this.composeData.id = data.id;
        this.composeData.date_debut =formattedDate;
        this.composeData.text = data.text;
        this.composeData.duree = data.duree;
        this.composeData.popup_url = data.popup_url
        this.title = 'Modifier Pop-up'
  
      },
      handleSubmit() {
        const formData = new FormData();
        this.$refs.webinarpopups.validate().then((success) => {
        if (success) {
            this.showLoading = true;
            formData.append("id", this.composeData.id);
            formData.append("text", this.composeData.text);
            formData.append("date_debut", this.composeData.date_debut);
            formData.append("duree", this.composeData.duree);
            formData.append("popup_url", this.composeData.popup_url);
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            this.$http
                .post("/webinars/storeWebinarPopup", formData, config)
                .then((res) => {
                    if (res.data.success) {
                        this.clearData();
                        this.hide();
                        if (res.data.data.isCreated) {
                            this.popups.push(res.data.data.data);
                        } else {
                            let index = this.popups.findIndex(
                                (webinar) => webinar.id == res.data.data.data.id
                            );
                            this.$set(this.popups, index, res.data.data.data);
                        }
                        this.showLoading = false;
                        this.messageToast(
                            res.data.message,
                            "Succès",
                            "success"
                        );
                    } else {
                        this.showLoading = false;
                        this.messageToast(
                            res.data.message,
                            "Erreur",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    this.showLoading = false;
                    if (error.response && error.response.data && error.response.data.message) {
                        this.messageToast(
                            error.response.data.message,
                            "Erreur",
                            "error"
                        );
                    } else {
                        this.messageToast(
                            "Erreur lors de la création du nouveau webinar.",
                            "Erreur",
                            "error"
                        );
                    }
                });
        }
    });
},
 setup() {
    const colors = ref([])
    colors.value = colorPicker.colors
    return {
      colors
    }
  },
      deletePopup(id) {
        this.$swal({
          title: "Etes-vous sûr de vouloir supprimer ce popup ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.showLoading = true;
            this.$http
              .post(`/webinars/delete-popup/${id}`)
              .then((res) => {
                if (res.data.success) {
                  const indexWebinar = this.popups.findIndex(
                    (item) => item.id === id
                  );
                  this.popups.splice(indexWebinar, 1);
                  this.showLoading = false;
                  this.messageToast(
                    "Le webinar a bien été supprimé",
                    "Succès",
                    "success"
                  );
                }
              })
              .catch(() => {
                this.showLoading = false;
                  this.messageToast(
                    "Erreur lors supprimé webinar.",
                    "Erreur",
                    "error"
                  );
              });
          }
        });
      },
      filterWebinars(){
        return this.popups;
      }
    },
  };
  
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/quill.scss';

  div.custom-table-bottom > div > .vgt-responsive {
    min-height: 0 !important;
  }
  .custom-width {
      max-width: 200px; /* Ajustez selon vos besoins */
      width: 200px;
      white-space: nowrap;
      
  }
  .w-15 {
    width: 15%;
    max-width:15%;
  }
  .w-25 {
    width: 25%;
    max-width:25%;
  }
  </style>