<template>
  <ListWebinars :showInBackOffice="false" />
</template>

<script>
import ListWebinars from './ListWebinars.vue'

export default {
 components: {
  ListWebinars
},
  data(){
    return{
      webinars:[],
      ateliers:[],
      popups:[]
    }
  }
 ,
 
}
</script>

<style>

</style>