<template>
    <div>
      <!-- Title page -->
      <div class="mx-auto mb-2">
        <b-row class="justify-content-end align-items-center">
          
          <!-- Headers Buttons -->
          <b-col sm="5" v-if="showInBackOffice">
            <div class="custom-search d-flex justify-content-end">
              <b-button
                v-b-toggle.sidebar-2
                variant="primary"
                size="md"
                
              >
                <feather-icon icon="PlusIcon" />
                <span class="align-middle"> Ajouter Atelier</span>
              </b-button>
            </div>
          </b-col>
          <!-- Sidebar of Add and Update Atelier -->
          <b-sidebar
            id="sidebar-2"
            ref="sideBarAtelier"
            bg-variant="white"
            sidebar-class="sidebar-lg"
          @hidden="hide"
            shadow
            backdrop
            no-header
            right
          >
            <template>
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  content-sidebar-header
                  px-2
                  py-1
                "
              >
                <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
                  {{ title }}
                </h5>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
                />
              </div>
              <validation-observer ref="atelierForm">
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset.prevent="resetForm"
                >
                  <b-overlay :show="showLoading" no-wrap />

                  

                  <b-form-group label="Titre du l'atelier*" label-for="titre">
                    <validation-provider
                      #default="{ errors }"
                      name="Titre du atelier"
                      rules="required"
                    >
                      <b-form-input
                        v-model="composeData.title"
                        id="titre"
                        placeholder="Titre du atelier"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Description*" label-for="description">
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="composeData.description"
                        id="description"
                        placeholder="Écrire la description"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Date atelier*" label-for="date-atelier"> 
                    <validation-provider v-slot="{ errors }" name="date atelier" rules='required'> 
                        <flat-pickr id="date d'atelier" v-model="composeData.atelier_date"
                        placeholder="Date Atelier" :config="configDate" 
                        :class="errors.length > 0 ? 'is-invalid' : ''"  />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                        </b-form-invalid-feedback> 
                    </validation-provider>  
                  </b-form-group>


                  <b-form-group label="Lien de la prochaine session*" label-for="lien_atelier">
                    <validation-provider
                      #default="{ errors }"
                      name="Lien de la prochaine session"
                      rules=""
                    >
                      <b-form-input
                        v-model="composeData.atelier_url"
                        id="lien_atelier"
                        placeholder="Lien de l'atelier"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-form-group>

                  


                  <b-button @click="handleSubmit" variant="primary" size="md">
                    <span class="align-middle">Enregistrer</span>
                  </b-button>
                </b-form>
              </validation-observer>
            </template>
          </b-sidebar>
        </b-row>
      </div>
      <!-- List of Ateliers -->
      <b-overlay :show="showLoading" no-wrap />
      <vue-good-table
        class="custom-table-bottom"
        :columns="ateliersColumns"
        :rows="ateliers"
        :rtl="direction"
        style-class="vgt-table condensed"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: pageLength,
          rowsPerPageLabel: '',
          dropdownAllowAll: false,
          perPageDropdownEnabled: false,
          nextLabel: 'Suivant',
          prevLabel: 'Précédent',
          ofLabel: 'de',
          infoFn: (params) => ``,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'id'">

           <span v-if="!showInBackOffice" class="cursor-pointer " style="text-decoration: underline;" ><b-button @click="redirectToAtelierURL(props.row.id)"  variant="none">je m'inscris à la prochaine session</b-button></span>
           <span v-else>
            <span class="cursor-pointer">
              <feather-icon
                @click="deleteAtelier(props.row.id)"
                icon="TrashIcon"
                size="20"
                class="mr-50"
              />
            </span>
             <span class="cursor-pointer">
                <b-button v-b-toggle.sidebar-2
                size="20"
                class="p-0 mr-50"
                variant="none"
                @click="editFunction(props.row)"
                >
                <feather-icon size="20" icon="EditIcon"  class="mr-50" />
                </b-button>
              </span>
           </span>
          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <b-row class="mt-2 align-items-center">
            <b-col md="6" lg="5" xxl="3">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Affichage 1 à </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> de {{ props.total }} entrées </span>
              </div>
            </b-col>
          </b-row>
          
          <b-row class="mt-2 align-items-center">
            <b-col>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>
        <div slot="emptystate" class="text-center align-middle">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
      </vue-good-table>
      
    </div>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import flatPickr from 'vue-flatpickr-component'
  import {
    VBTooltip,
    BFormInvalidFeedback,
    BInputGroupPrepend,
    BFormFile,
    BFormInput,
    BFormGroup,
    BPagination,
    BForm,
    VBToggle,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    BFormSelect,
    BSidebar,
    BFormTextarea,
    BInputGroup,
    BTooltip,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import store from "@/store/index";
  import { VueGoodTable } from "vue-good-table";
  import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
  export default {
    components: {
      flatPickr,
      BFormInvalidFeedback,
      VBToggle,
      BInputGroupPrepend,
      BFormFile,
      BFormInput,
      BFormGroup,
      BForm,
      BFormTextarea,
      BInputGroup,
      ValidationProvider,
      ValidationObserver,
      BDropdown,
      BDropdownItem,
      BRow,
      BCol,
      BSidebar,
      BButton,
      BCard,
      BCardText,
      BCardHeader,
      BMedia,
      BImg,
      BOverlay,
      VueGoodTable,
      BPagination,
      BFormSelect,
      ToastificationContentVue,
    },
    directives: {
      "b-toggle": VBToggle,
      "b-tooltip": VBTooltip,
      Ripple,
    },
    props: {
      showInBackOffice: {
        type: Boolean,
        default: false,
      },
      ateliers:{
        type: Array,
        default: []
      }
    },
    data() {
      return {
        showLoading: false,
        pageLength: 5,
        dir: false,
        renderComponent: true,
        modalAction: "Nouveau",
        ateliersColumns: [
       
          {
            label: "Titre du l'atelier",
            field: "title",
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking",
            tdClass: "text-center m-0 p-2 align-middle",
          },
          {
            label: "Description",
            field: "description",
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking",
            tdClass: "text-center m-0 p-2 align-middle",
          },
          {
            label: "Actions",
            field: "id",
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking",
            tdClass: "text-center m-0 p-2 align-middle",
          },
        ],
        composeData: {
          description: "",
          atelier_url: "",
          atelier_date: "",
          id: null,
          title:"",
          status:"",
        },
        title:'Nouvelle Atelier',
        configDate: {
                dateFormat: 'Y/m/d',
                altFormat: 'd/m/Y',
                altInput: true,      
                allowInput: true,
                onKeyDown: (selectedDates, dateStr, instance, event) => {
                    if (event.keyCode != 8) {
                    var ele = document.activeElement;
                    var val = ele.value;

                    if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                        val += "/";
                    }
                    ele.value = val;
                    ele.dispatchEvent(new Event("input"));
                    }
                },
            }
      };
    },
    computed: {
      direction() {
        if (store.state.appConfig.isRTL) {
          this.dir = true;
          return this.dir;
        }
        this.dir = false;
        return this.dir;
      },
      
     
    },
    watch: {
    'composeData.atelier_date'(newDate) {
        const today = new Date();
        const selectedDate = new Date(newDate);
        if (selectedDate > today) {
            this.composeData.status = 'venir'
        } else {
            this.composeData.status = 'passe'
        }
        }
    },
    methods: {
      forceRerender() {
        this.renderComponent = false;
        this.$nextTick(() => {
          this.renderComponent = true;
        });
      },
      hide() {
        this.$refs.sideBarAtelier.hide();
        this.clearData();
        this.title='Nouvelle Atelier'
  
      },
      redirectToAtelierURL(id){
        let atelierURL = this.ateliers.find(ate=>ate.id == id).atelier_url
        window.open(atelierURL)
      },
      messageToast(text, title, variant) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title,
            icon: "BellIcon",
            text,
            variant,
          },
        });
      },
    
      
      clearData() {
        this.composeData.id = null;
        this.composeData.title = "";
        this.composeData.description = "";
        this.composeData.atelier_url = "";
        this.composeData.atelier_date = "";
        this.composeData.status = "";
        this.$refs.atelierForm.reset();
     
      },
      editFunction(data){
        this.composeData.id = data.id;
        this.composeData.title = data.title;
        this.composeData.atelier_url = data.atelier_url;
        this.composeData.atelier_date = data.atelier_date;
        this.composeData.description = data.description;
        this.composeData.status = data.status;
        this.title = 'Modifier Atelier'
  
      },
      handleSubmit() {
        const formData = new FormData();
        this.$refs.atelierForm.validate().then((success) => {
          if (success) {
            this.showLoading = true;
            formData.append("title", this.composeData.title);
            formData.append("description", this.composeData.description);
            formData.append("id", this.composeData.id);
            formData.append("atelier_url", this.composeData.atelier_url);
            formData.append("atelier_date", this.composeData.atelier_date);
            formData.append("status", this.composeData.status);
            this.$http
              .post("/webinars/add-atelier", formData)
              .then((res) => {
                if (res.data.success) {
                  this.clearData();
                  this.hide();
                  if(res.data.data.isCreated){
                    this.ateliers.push(res.data.data.data);
                  }else{
                    let index = this.ateliers.findIndex(
                      (atelier) => atelier.id == res.data.data.data.id
                    );
                    this.ateliers[index].description = res.data.data.data.description;
                    this.ateliers[index].title = res.data.data.data.title;
                    this.ateliers[index].atelier_url = res.data.data.data.atelier_url;
                    this.ateliers[index].atelier_date = res.data.data.data.atelier_date;
                    this.ateliers[index].status = res.data.data.data.status;
                  }
                  this.showLoading = false;
                  this.messageToast(
                    res.data.message,
                    "Succès",
                    "success"
                  );
                } else {
                  this.showLoading = false;
                  this.messageToast(
                    "Erreur lors de creation de nouveau Atelier.",
                    "Erreur",
                    "error"
                  );
                }
              })
              .catch(() => {
                this.showLoading = false;
                this.messageToast(
                    "Erreur lors de creation de nouveau Atelier.",
                    "Erreur",
                    "error"
                  );
              });
          }
        });
      },
      deleteAtelier(id) {
        this.$swal({
          title: "Etes-vous sûr de vouloir supprimer cette Atelier ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.showLoading = true;
            this.$http
              .post(`/webinars/delete-atelier/${id}`)
              .then((res) => {
                if (res.data.success) {
                  const indexAtelier = this.ateliers.findIndex(
                    (item) => item.id === id
                  );
                  this.ateliers.splice(indexAtelier, 1);
                  this.showLoading = false;
                  this.messageToast(
                    "Webinar a été Bien supprimer",
                    "Succès",
                    "success"
                  );
                }
              })
              .catch(() => {
                this.showLoading = false;
                this.messageToast(
                    "Erreur lors supprimé atelier.",
                    "Erreur",
                    "error"
                  );
              });
          }
        });
      },
    },
  };
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  div.custom-table-bottom > div > .vgt-responsive {
    min-height: 0 !important;
  }
  </style>