<template>
    <div>
      
      <div class="mx-auto mb-2">
        <b-row class="justify-content-end align-items-center">
          
          <!-- Headers Buttons -->
          <b-col sm="5" v-if="showInBackOffice">
            <div class="custom-search d-flex justify-content-end">
              <b-button
                v-b-toggle.sidebar-1
                variant="primary"
                size="md"
                
              >
                <feather-icon icon="PlusIcon" />
                <span class="align-middle"> Ajouter Webinar</span>
              </b-button>
            </div>
          </b-col>
          <!-- Sidebar of Add and Update Webinar -->
          <b-sidebar
            id="sidebar-1"
            ref="sideBarWebinar"
            bg-variant="white"
            sidebar-class="sidebar-lg"
          @hidden="hide"
            shadow
            backdrop
            no-header
            right
          >
            <template>
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  content-sidebar-header
                  px-2
                  py-1
                "
              >
                <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
                  {{ title }}
                </h5>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
                />
              </div>
              <validation-observer ref="webinardocument">
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(onSubmit)"
                  @reset.prevent="resetForm"
                >
                  <b-overlay :show="showLoading" no-wrap />
                  <b-form-group label="Titre du webinar*" label-for="titre">
                    <validation-provider
                      #default="{ errors }"
                      name="Titre du webinar"
                      rules="required"
                    >
                      <b-form-input
                        v-model="composeData.nom"
                        id="titre"
                        placeholder="Titre du webinar"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-form-group>


                  <b-form-group label="Description*" label-for="description">
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="composeData.description"
                        id="description"
                        placeholder="Écrire la description"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Date Du webinar*" label-for="date-webinar"> 
                    <validation-provider v-slot="{ errors }" name="Date du webinar" rules='required'> 
                        <flat-pickr id="date webinar" v-model="composeData.webinar_date"
                        placeholder="Date Webinar" :config="configDate" 
                        :class="errors.length > 0 ? 'is-invalid' : ''"  />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                        </b-form-invalid-feedback> 
                    </validation-provider>  
                  </b-form-group>

                  <b-form-group label="Lien du webinar*" label-for="webinar_url" v-if="showWebinarURL">
                    <validation-provider
                      #default="{ errors }"
                      name="Lien du webinar"
                      rules="required"
                    >
                      <b-form-input
                        v-model="composeData.webinar_url"
                        id="webinar_url"
                        placeholder="Lien de webinar"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-form-group>


                  <b-form-group label="Lien de video*" label-for="lien_video" v-if="showVideoLink">
                    <validation-provider
                      #default="{ errors }"
                      name="Lien de video"
                      rules="required"
                    >
                      <b-form-input
                        v-model="composeData.lien_video"
                        id="lien_video"
                        placeholder="Lien de video"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                        >{{ errors[0] }}</b-form-invalid-feedback
                      >
                    </validation-provider>
                  </b-form-group>


                  <b-form-group label="Nom de document" label-for="action">
                    <validation-provider
                      #default="{ errors }"
                      name="document"
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-button
                            id="action"
                            variant="primary"
                            class="btn-icon"
                            v-b-tooltip.hover="'Parcourir le fichier'"
                            @click="openToSelectFile"
                          >
                            <b-form-file
                              type="file"
                              id="file"
                              class="d-none"
                              v-model="composeData.document"
                            />
                            <feather-icon icon="UploadCloudIcon" />
                          </b-button>
                        </b-input-group-prepend>
                        <b-form-input
                          disabled
                          placeholder="Choisissez un document"
                          :value="
                            composeData.document ? composeData.document.name : ''
                          "
                        />
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                        >{{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                  <b-button @click="handleSubmit" variant="primary" size="md">
                    <span class="align-middle">Enregistrer</span>
                  </b-button>
                </b-form>
              </validation-observer>
            </template>
          </b-sidebar>
        </b-row>
      </div>
      <!-- List of Webinar -->
      <b-overlay :show="showLoading" no-wrap />
      <vue-good-table
        class="custom-table-bottom"
        :columns="webinarsColumns"
        :rows="filterWebinars(showInBackOffice)"
        :rtl="direction"
        style-class="vgt-table condensed"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: pageLength,
          rowsPerPageLabel: '',
          dropdownAllowAll: false,
          perPageDropdownEnabled: false,
          nextLabel: 'Suivant',
          prevLabel: 'Précédent',
          ofLabel: 'de',
          infoFn: (params) => ``,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'id'">
            <b-dropdown
              dropright
              no-caret
              toggle-class="text-decoration-none p-0"
              variant="link" v-if="props.row.document_id > 0 || props.row.lien_video"
            >
              <template v-slot:button-content>
                <feather-icon
                  style="color: #6e6b7b"
                  icon="DownloadCloudIcon"
                  size="20"
                  class="mr-50"
                />
              </template>
              <b-dropdown-item v-if="props.row.document_id > 0"
                @click="getBase64Document(props.row.document_id, props.row.nom)"
              >
                <b-img
                  style="height:30px;"
                  :src="require('@/assets/images/icons/doc.png')"
                />
              </b-dropdown-item>
              <b-dropdown-item v-if="props.row.lien_video" :href="props.row.lien_video" target="_blank">
                <b-img
                  style="height:30px;"
                  :src="require('@/assets/images/icons/video.png')"
                />
              </b-dropdown-item>
            </b-dropdown>
            <span v-if="showInBackOffice" class="cursor-pointer">
              <feather-icon
                @click="deleteWebinar(props.row.id)"
                icon="TrashIcon"
                size="20"
                class="mr-50"
              />
            </span>
            <span v-if="showInBackOffice" class="cursor-pointer">
              <b-button v-b-toggle.sidebar-1
              size="20"
              class="p-0 mr-50"
              variant="none"
              @click="editFunction(props.row)"
              >
              <feather-icon size="20" icon="EditIcon"  class="mr-50" />
              </b-button>
            </span>
          </span>
          <!-- Column Date -->
          <span v-else-if="props.column.field === 'webinar_date'">
           <span class="" v-if="nextWebinareURL !== null && props.row.id === nextWebinareURL.id && !showInBackOffice"><feather-icon size="22" icon="StarIcon"  class="align-top text-warning" /></span> {{ transformedDate(props.row.webinar_date) }}
          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <b-row  class="mt-2 mb-4 align-items-center">
            <b-col md="12" class="text-center" v-if="!showInBackOffice">
               <!-- button registering for next webinar -->
  <b-button  v-if="nextWebinareURL !== null && nextWebinareURL.webinar_url"  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="md" class="text-center">
          <a class="align-middle text-uppercase text-white" :href="nextWebinareURL.webinar_url" target="_blank">je m'inscris au prochaine webinar</a>
        </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2 align-items-center">
            <b-col md="6" lg="5" xxl="3">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Affichage 1 à </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> de {{ props.total }} entrées </span>
              </div>
            </b-col>
          </b-row>
          
          <b-row class="mt-2 align-items-center">
            <b-col>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>
        <div slot="emptystate" class="text-center align-middle">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
      </vue-good-table>
      
    </div>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import flatPickr from 'vue-flatpickr-component'
  import {
    VBTooltip,
    BFormInvalidFeedback,
    BInputGroupPrepend,
    BFormFile,
    BFormInput,
    BFormGroup,
    BPagination,
    BForm,
    VBToggle,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    BFormSelect,
    BSidebar,
    BFormTextarea,
    BInputGroup,
    BTooltip,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import store from "@/store/index";
  import { VueGoodTable } from "vue-good-table";
  import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from 'moment';
import 'moment/locale/fr'; // Import French locale
moment.locale('fr');
  export default {
    components: {
      BFormInvalidFeedback,
      VBToggle,
      BInputGroupPrepend,
      BFormFile,
      BFormInput,
      BFormGroup,
      BForm,
      BFormTextarea,
      BInputGroup,
      ValidationProvider,
      ValidationObserver,
      BDropdown,
      BDropdownItem,
      BRow,
      flatPickr,
      BCol,
      BSidebar,
      BButton,
      BCard,
      BCardText,
      BCardHeader,
      BMedia,
      BImg,
      BOverlay,
      VueGoodTable,
      BPagination,
      BFormSelect,
      ToastificationContentVue,
    },
    directives: {
      "b-toggle": VBToggle,
      "b-tooltip": VBTooltip,
      Ripple,
    },
    props: {
      showInBackOffice: {
        type: Boolean,
        default: false,
      },
      webinars:{
        type: Array,
        default: []
      }
    },
    data() {
      return {
        showLoading: false,
        pageLength: 5,
        dir: false,
        renderComponent: true,
        modalAction: "Nouveau",
        webinarsColumns: [
        {
            label: "Date du webinar",
            field: "webinar_date",
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking",
            tdClass: "text-center m-0 p-2 align-middle",
          },
          {
            label: "Titre du webinar",
            field: "nom",
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking",
            tdClass: "text-center m-0 p-2 align-middle",
          },
          {
            label: "Description",
            field: "description",
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking",
            tdClass: "text-center m-0 p-2 align-middle",
          },
          {
            label: "Télécharger le replay",
            field: "id",
            sortable: true,
            thClass: "text-center align-middle bg-primary text-white unbreaking",
            tdClass: "text-center m-0 p-2 align-middle",
          },
        ],
        composeData: {
          document: null,
          description: null,
          nom: null,
          webinar_date:"",
          webinar_url:"",
          lien_video: null,
          id: null,
          document_id:null,
          status:null
        },
        showWebinarURL: false,
        showVideoLink: false,
        title:'Nouvelle Webinar',
        configDate: {
                dateFormat: 'Y/m/d',
                altFormat: 'd/m/Y',
                altInput: true,      
                allowInput: true,
                onKeyDown: (selectedDates, dateStr, instance, event) => {
                    if (event.keyCode != 8) {
                    var ele = document.activeElement;
                    var val = ele.value;

                    if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                        val += "/";
                    }
                    ele.value = val;
                    ele.dispatchEvent(new Event("input"));
                    }
                },
            }
      };
    },

    computed: {
      direction() {
        if (store.state.appConfig.isRTL) {
          this.dir = true;
          return this.dir;
        }
        this.dir = false;
        return this.dir;
      },
      nextWebinareURL(){
        const currentDatetime = moment();
        const upcomingWebinars = this.webinars.filter((webinar)=>{
          return moment(webinar.webinar_date) > currentDatetime && webinar.status == 'venir'
        });
        if(upcomingWebinars.length > 0){
          upcomingWebinars.sort((a,b)=> moment(a.webinar_date) - moment(b.webinar_date));
          return upcomingWebinars[0]
        }
        return null;
      },
      
    },

    watch: {
    'composeData.webinar_date'(newDate) {
        const today = new Date();
        const selectedDate = new Date(newDate);

        if (selectedDate > today) {
            this.showWebinarURL = true;
            this.showVideoLink = false;
            this.composeData.status = 'venir'
            this.composeData.lien_video = '';
        this.$refs.webinardocument.reset();
        } else {
            this.showWebinarURL = false;
            this.showVideoLink = true;
            this.composeData.status = 'passe'
            this.composeData.webinar_url = '';
        this.$refs.webinardocument.reset();
        }
        }
    },
    methods: {
      forceRerender() {
        this.renderComponent = false;
        this.$nextTick(() => {
          this.renderComponent = true;
        });
      },
      hide() {
        this.$refs.sideBarWebinar.hide();
        this.clearData();
        this.title='Nouvelle Webinar'
  
      },
      transformedDate(date) {
      
      const formattedDate = moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY");

      return formattedDate;
    },
      openToSelectFile: () => {
        document.getElementById("file").click();
      },
      messageToast(text, title, variant) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title,
            icon: "BellIcon",
            text,
            variant,
          },
        });
      },
      
      getBase64Document(id, name) {
        this.$http
          .get(`/document/generate_base64_for_document/${id}`)
          .then((res) => {
            if (res.data.success) {
              this.downloadDocument(res.data.data.base64, name);
            } else {
              this.messageToast(res.data.message, "Erreur", "error");
            }
          })
          .catch((err) => {
            this.messageToast(err.response.data.errors[0], "Erreur", "error");
            console.error(err);
          });
      },
      downloadDocument(base64, name) {
        if (base64) {
          const a = document.createElement("a");
          a.href = base64;
          a.download = name;
          a.click();
          this.messageToast(
            "Le document a été téléchargé avec succès.",
            "Succès",
            "success"
          );
        } else {
          this.messageToast(
            "Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.",
            "Erreur",
            "warning"
          );
        }
      },
      clearData() {
        this.composeData.id = null;
        this.composeData.nom = "";
        this.composeData.webinar_date = "";
        this.composeData.description = "";
        this.composeData.lien_video = "";
        this.composeData.document = null;
        this.composeData.document_id = null
        this.composeData.status = null
        this.composeData.webinar_url = ""
        this.$refs.webinardocument.reset();
      },
      editFunction(data){
        this.composeData.id = data.id;
        this.composeData.nom = data.nom;
        this.composeData.webinar_date = data.webinar_date;
        this.composeData.lien_video = data.lien_video;
        this.composeData.description = data.description;
        this.composeData.document_id = data.document_id
        this.composeData.status = data.status
        this.composeData.webinar_url = data.webinar_url
        this.title = 'Modifier Webinar'
  
      },
      handleSubmit() {
        const formData = new FormData();
        this.$refs.webinardocument.validate().then((success) => {
          if (success) {
            this.showLoading = true;
            if(this.composeData.status == "passe"){
              this.composeData.webinar_url = ""
            }else{
              this.composeData.lien_video = ""

            }
            formData.append("nom", this.composeData.nom);
            formData.append("webinar_date", this.composeData.webinar_date);
            formData.append("description", this.composeData.description);
            formData.append("document", this.composeData.document);
            formData.append("id", this.composeData.id);
            formData.append("status", this.composeData.status);
            formData.append("lien_video", this.composeData.lien_video);
            formData.append("webinar_url", this.composeData.webinar_url);
            formData.append("document_id", this.composeData.document_id);
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            this.$http
              .post("/webinars/add-webinar", formData, config)
              .then((res) => {
                if (res.data.success) {
                  this.clearData();
                  this.hide();
                  if(res.data.data.isCreated){
                      this.webinars.push(res.data.data.data);
                  }else{
                    let index = this.webinars.findIndex(
                      (webinar) => webinar.id == res.data.data.data.id
                    );
                    this.webinars[index].description = res.data.data.data.description;
                    this.webinars[index].nom = res.data.data.data.nom;
                    this.webinars[index].webinar_date = res.data.data.data.webinar_date;
                    this.webinars[index].lien_video = res.data.data.data.lien_video;
                    this.webinars[index].webinar_url = res.data.data.data.webinar_url;
                    this.webinars[index].document_id = res.data.data.data.document_id;
                  }
                  this.showLoading = false;
                  this.messageToast(
                    res.data.message,
                    "Succès",
                    "success"
                  );
                } else {
                  this.showLoading = false;
                  this.messageToast(
                    "Erreur lors de creation de nouveau webinar.",
                    "Erreur",
                    "error"
                  );
                }
              })
              .catch(() => {
                this.showLoading = false;
                  this.messageToast(
                    "Erreur lors de creation de nouveau webinar.",
                    "Erreur",
                    "error"
                  );
              });
          }
        });
      },
      deleteWebinar(id) {
        this.$swal({
          title: "Etes-vous sûr de vouloir supprimer cette webinar ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.showLoading = true;
            this.$http
              .post(`/webinars/delete-webinar/${id}`)
              .then((res) => {
                if (res.data.success) {
                  const indexWebinar = this.webinars.findIndex(
                    (item) => item.id === id
                  );
                  this.webinars.splice(indexWebinar, 1);
                  this.showLoading = false;
                  this.messageToast(
                    "Le webinar a bien été supprimé",
                    "Succès",
                    "success"
                  );
                }
              })
              .catch(() => {
                this.showLoading = false;
                  this.messageToast(
                    "Erreur lors supprimé webinar.",
                    "Erreur",
                    "error"
                  );
              });
          }
        });
      },
      filterWebinars(showInBackOffice){
        if(!showInBackOffice){
          let result = this.webinars.filter((webinar)=>{
            return webinar.status == 'passe'
          })
          if(this.nextWebinareURL){
            result.unshift(this.nextWebinareURL)
          }
          return result
        }
        return this.webinars;
      }
    },
  };
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  div.custom-table-bottom > div > .vgt-responsive {
    min-height: 0 !important;
  }
  </style>